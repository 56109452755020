/* You can add global styles to this file, and also import other style files */
// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';

/******************************* app styles ****************************/
@import './assets/styles/botones.scss';
@import './assets/styles/dropdown.scss';
@import './assets/styles/inputs.scss';
@import './assets/styles/checkboxes.scss';

@font-face {
    font-family: Rubik-Ligth;
    src: url('./assets/fonts/rubik/Rubik-Light.ttf') format("opentype");
}

.container {
    height: 100vh;
    background: black;
    color: white;
}

.app-root{
    display:block;
    height:100vh;
}

html, body {
    min-height: 100%;
    height: auto;
    margin: 0;

    background: /* #b993d6 */#fff;
   /*  background: -webkit-linear-gradient(to right, #b993d6, #8ca6db);
    background: linear-gradient(to right, #b993d6, #8ca6db); */
}

* {
    font-family: Rubik-Ligth;
}





